import React from "react"

import Img from "gatsby-image"
import { LinklessButton } from "../../components/button"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { Absolute, Heading, Padding, Text } from "../../common/styles"
import { media } from "../../common/mediaQueries"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { HEADER_HEIGHT } from "../../components/header"

const Hero = () => (
  <StaticQuery
    query={graphql`
      query {
        hero: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1980, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={({ hero }) => (
      <Wrapper>
        <Img alt="Patricia On Ice - Foto" fluid={hero.childImageSharp.fluid} />
        <HeroTextWrapper>
          <Text>Patricia Kühne | Professionelle Eiskunstläuferin </Text>
          <Padding value=".5vw" axis="y" />
          <StyledHeading>FOLLOW YOUR PASSION!</StyledHeading>
          <Padding value="2vw" axis="y" />
          <Text>
          <StyledAnchorLink href="#showcase" offset={HEADER_HEIGHT.desktop}>Ice Show</StyledAnchorLink> | <StyledAnchorLink href="#showcase" offset={HEADER_HEIGHT.desktop}>Eislaufkurse & Personal Training</StyledAnchorLink>
            <br />
            <StyledAnchorLink href="#showcase" offset={HEADER_HEIGHT.desktop}>Choreographie für Eiskunstlauf & Shows</StyledAnchorLink> |  <StyledAnchorLink href="#mobileEisbahn" offset={HEADER_HEIGHT.desktop}>Mobile Eisbahn</StyledAnchorLink>
          </Text>
          <Padding value="2vw" axis="y" />
          <div style={{ textAlign: "left" }}>
            <StyledAnchorLink href="#showcase" offset={HEADER_HEIGHT.desktop}>
              <LinklessButton content="Mehr erfahren" look="primary" />
            </StyledAnchorLink>
          </div>
        </HeroTextWrapper>
      </Wrapper>
    )}
  />
)

const Wrapper = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  ${media.mobile} {
    display: none;
  }
`

const HeroTextWrapper = styled(Absolute)`
  right: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 14vw;
  padding-top: 7vw;
  color: white;
`
const StyledHeading = styled(Heading)`
  font-size: 4vw;
`

const StyledAnchorLink = styled(AnchorLink)`
  color: white;
`

export default Hero
