import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../page-content/index/hero"
import Showcase from "../page-content/index/showcase"
import AboutMe from "../page-content/index/aboutme"
import MobileEisbahn from "../page-content/index/mobileEisbahn"
import Video from "../page-content/index/video"

//TODO: Add SEO-keywords
//TODO: Add content

const IndexPage = () => (
  <>
    <Layout>
      <SEO
        title="Home"
        keywords={[
          "Patricia On Ice",
          "Eiskunstlauf",
          "Ice Show",
          "Choreographie",
          "Personal Training",
          "Mobile Eisbahn",
          "Dresden",
          "Sachsen",
        ]}
        description="Professioneller Eiskunstlauf - mit Ice shows, Choreographien und Personal Training mache ich Ihr Event mit mobiler Eisbahn zum Erlebnis!"
      />
      <Hero />
      <Showcase />
      <AboutMe />
      <MobileEisbahn />
      <Video />
    </Layout>
  </>
)

export default IndexPage
