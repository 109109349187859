import React from "react"

import Img from "gatsby-image"
import { LinklessButton } from "../../components/button"
import { StaticQuery, graphql, navigate } from "gatsby"
import styled from "@emotion/styled"
import parse from "html-react-parser"
import {
  Absolute,
  SubHeading,
  Text,
  Padding,
  PinkDelimiter,
  styles,
} from "../../common/styles"
import { media } from "../../common/mediaQueries"

const Showcase = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          pat2: file(relativePath: { eq: "patricia-on-ice-2.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          pat3: file(relativePath: { eq: "patricia-on-ice-3.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          pat4: file(relativePath: { eq: "patricia-on-ice-4.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={({ pat2, pat3, pat4 }) => {
        //TODO: Adjust text under Choreographie "und einige weitere" and under ICE Show
        const data = [
          {
            headline: "Ice Show",
            text:
              "Buchen Sie Ihre persönliche Ice Show! <br/> Damit Ihre Firmenveranstaltung oder private Feier <br/> unvergesslich wird.",
            key: "show",
            pic: pat3,
          },
          {
            headline: "Eislaufkurse & Personal Training",
            text:
              `Gönnen Sie sich Eislauftraining mit einem Profi!<br/>
              So erreichen Sie garantiert <br/>das nächste Level!
              `,
            key: "training",
            pic: pat4,
          },
          {
            headline: "Choreografie für Eiskunstlauf und Shows",
            text:
              `Choreografien die Ihre Visionen zum Leben erwecken!<br/>
              Auf dem Eis, als Dance Show <br/>oder als Fashion Show.
              `,
            key: "choreografie",
            pic: pat2,
          },
        ]

        return (
          <Wrapper id="showcase">
            {data.map((dataItem, idx) => (
              <ImageWrapper key={idx}>
                <Img
                  alt={`Patricia On Ice - Foto '${dataItem.headline}'`}
                  fluid={dataItem.pic.childImageSharp.fluid}
                />
                <TextWrapper>
                  <SubHeading>{dataItem.headline}</SubHeading>
                  <Padding value="1vw" axis="y" />
                  <StyledPinkDelimiter />
                  <Padding value="1vw" axis="y" />
                  <Text>{parse(dataItem.text)}</Text>
                  <Padding value="1.6vw" axis="y" />
                  <span
                    onClick={() =>
                      navigate(null, { state: { modalXActive: dataItem.key } })
                    }
                  >
                    <LinklessButton look="primary" content="Mehr erfahren" />
                  </span>
                  <Padding value="2.75vw" axis="y" />
                </TextWrapper>
              </ImageWrapper>
            ))}
          </Wrapper>
        )
      }}
    />
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  background-image: linear-gradient(
    to right,
    ${styles.colors.blue.dark},
    ${styles.colors.blue.dark},
    ${styles.colors.violet},
    ${styles.colors.violet}
  );
  ${media.mobile} {
    flex-direction: column;
    background-image: linear-gradient(
      ${styles.colors.blue.dark},
      ${styles.colors.blue.dark},
      ${styles.colors.violet},
      ${styles.colors.violet}
    );
  }
`
const StyledPinkDelimiter = styled(PinkDelimiter)`
  margin: 0px auto;
`

const ImageWrapper = styled.div`
  position: relative;
  width: calc(100vw / 3);
  overflow: hidden;
  height: 25vw;
  > .gatsby-image-wrapper {
    opacity: 0.7;
  }
  ${media.mobile} {
    width: 100vw;
    height: 70vw;
  }
`
const TextWrapper = styled(Absolute)`
  color: white;
  top: auto;
  text-align: center;
  padding: 0 1.5vw;
  ${media.mobile} {
    padding: 0 6vw 5vw 6vw;
  }
`

export default Showcase
