import React from "react"

import styled from "@emotion/styled"
import { Heading, Padding, styles, PinkDelimiter } from "../../common/styles"
import { media } from "../../common/mediaQueries"

const Video = () => (
  <Wrapper id='video'>
    <Heading>ICE SHOW</Heading>
    <Padding value="2vw" axis="y" />
    <PinkDelimiter />
    <Padding value="2vw" axis="y" />
    <StyledIFrame
      title="Patricia On Ice - Youtube Trailer"
      src="https://www.youtube-nocookie.com/embed/Ye3SJRfzyws"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 5vw 10vw;
  display: flex;
  color: ${styles.colors.blue.dark};
  flex-direction: column;
  align-items: center;
  background-color: ${styles.colors.lightGrey};
`

const StyledIFrame = styled.iframe`
  width: 60vw;
  height: 33.75vw;
  ${media.mobile} {
    width: 80vw;
    height: 45vw;
  }
  ${media.wideDesktop} {
    width: 40vw;
    height: 22.5vw;
  }
`

export default Video
