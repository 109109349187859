import styled from "@emotion/styled"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { media } from "../../common/mediaQueries"
import {
  Absolute,
  Heading,
  Padding,
  PinkDelimiter,
  StrongText,
  styles,
  Text,
} from "../../common/styles"
import { Button } from "../../components/button"

const MobileEisbahn = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          pat1: file(relativePath: { eq: "patricia-on-ice-1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1980, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={({ pat1 }) => (
        <Wrapper id="mobileEisbahn">
          <ImageWrapper>
            <Img
              alt="Patricia On Ice - Foto"
              fluid={pat1.childImageSharp.fluid}
            />
            <TextWrapper>
              <Heading>Mobile Eisbahn</Heading>
              <Padding value="2vw" axis="y" />
              <PinkDelimiter />
              <Padding value="2vw" axis="y" />
              <StrongText>Sie haben keine Eisbahn? Kein Problem!</StrongText>
              <Padding value=".5vw" axis="y" />
              <StrongText>
                Mieten Sie sich doch einfach eine mobile Eisbahn von mir.
              </StrongText>
              <Padding value="1vw" axis="y" />
              <Text>
                <ul>
                  <li>Ganzjährig nutzbar im Sommer und Winter</li>
                  <li>Für Indoor und Outdoor geeignet</li>
                  <li>
                    Die Größe und die Form der Eisbahn kann an die
                    Platzverfügbarkeit angepasst werden
                  </li>
                  <li>Schneller Auf- und Abbau</li>
                  <li>Keine Energie- und Wasserkosten</li>
                </ul>
              </Text>
              <Button
                content="Interessiert? Jetzt Anfragen!"
                look="primary"
                linkTo={`/kontakt?3`}
              />
            </TextWrapper>
          </ImageWrapper>
        </Wrapper>
      )}
    />
  )
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  background-color: ${styles.colors.violet};
`

const ImageWrapper = styled.div`
  height: 100%;
  position: relative;
  flex: 0 0 90vw;
  > .gatsby-image-wrapper {
    opacity: 0.6;
  }
  ${media.mobile} {
    flex: 0 0 100vw;
  }
`

const TextWrapper = styled(Absolute)`
  height: 100%;
  display: flex;
  overflow: visible;
  flex-direction: column;
  color: white;
  right: 50%;
  padding: 7vw 0 10vw 4vw;
  ${media.mobile} {
    right: auto;
    padding: 7vw 7vw 10vw 4vw;
    position: relative;
  }
`

export default MobileEisbahn
