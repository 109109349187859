import styled from "@emotion/styled"
import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { media } from "../../common/mediaQueries"
import {
  Heading,
  Padding,
  PinkDelimiter,
  styles,
  Text,
} from "../../common/styles"
import MediaSlider from "../../components/mediaSlider"

const AboutMe = () => {
  const [activeTab] = useState(0)
  return (
    <StaticQuery
      query={graphql`
        query {
          pat6: file(relativePath: { eq: "patricia-on-ice-6.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1980, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          medallien: file(relativePath: { eq: "medallien.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1980, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={({ pat6, medallien }) => {
        const tabs = [
          {
            heading: "Meine Person",
            text:
              "<p>Mein Name ist Patricia Kühne. Ich bin professionelle Eiskunstläuferin, Trainerin und Choreografin.</p><p>Mit drei Jahren stand ich das erste Mal auf dem Eis und entdeckte meine Leidenschaft für das Eiskunstlaufen. 16 Jahre lang war ich in den aktiven Leistungssport eingebunden.</p><p>Seit 2012 begeistere ich das Publikum mit meinen Ice Shows und agiere mit Herzblut als Trainerin mit Lizenz und Choreografin im Leistungs- und Breitensportbereich.</p><p>Mehrmalige Sachsenmeisterin<br/>Vize Deutsche Meisterin<br/>Erster Platz Deutschland Pokal</p><p>Mein Papa war Eishockeyspieler bei den Dresdner Eislöwen. Als er mich mit 2 ¾ Jahren zum Schnuppertraining im Dresdner Eislauf-Club brachte, war die Trainerin sehr skeptisch. „Viel zu klein und viel zu jung“ meinte sie. Doch mein Papa stellte mich auf das Eis. Ich tippelte sofort los, durfte bleiben und entdeckte meine Liebe zum Eis.</p>",
            media: [
              { content: pat6, type: "image" },
              { content: medallien, type: "image" },
            ],
          },
        ]
        return (
          <Wrapper>
            <ImageWrapper>
              {tabs.map((tab, idx) =>
                idx !== activeTab ? null : (
                  <MediaSlider key={idx} media={tab.media} />
                )
              )}
            </ImageWrapper>
            <ContentWrapper>
              <Heading>Meine Person</Heading>
              <Padding value="2vw" axis="y" />
              <PinkDelimiter />
              <Padding value="3vw" axis="y" />
              {tabs.map((tab, idx) => (
                <Text key={idx}>{parse(tab.text)}</Text>
              ))}
            </ContentWrapper>
          </Wrapper>
        )
      }}
    />
  )
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  background-image: linear-gradient(
    ${styles.colors.blue.dark},
    ${styles.colors.blue.dark},
    ${styles.colors.blue.darkest}
  );
`

const ImageWrapper = styled.div`
  position: relative;
  height: 50vw;
  flex: 0 0 50%;
  opacity: 0.9;
  ${media.mobile} {
    display: none;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  width: 50vw;
  flex-direction: column;
  padding: 7vw 7vw 0 7vw;
  color: white;
  ${media.mobile} {
    width: 100%;
  }
`

export const Clickable = styled.span`
  color: ${props =>
    props.active ? styles.colors.magenta : styles.colors.white};
`

export default AboutMe
